<template>
  <b-button 
    class="px-1 pb-0 text-left"
    :class="`integrator-service-create-button-privacy${active ? '-active' : ''}`"
    @click="selectButton"
  >
    <h4 class="integrator-service-create-button-privacy-title">
      <feather-icon
        v-if="icon"
        :icon="icon"
        size="20"
      />
      <template v-if="title">
        {{ title }}
      </template>
    </h4>
    <p class="integrator-service-create-button-privacy-description">
      {{ description }}
    </p>
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

  export default {
    components: {
      BButton,
    },
    props: {
      title: {
        type: String,
        default: null
      },
      icon: {
        type: String,
        default: null
      },
      description: {
        type: String,
        default: ''
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      active: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      selectButton() {
        this.active = true
        this.$emit('selected')
      }

    }
  }
</script>

<style lang="scss" scoped>

.integrator-service-create-button-privacy-active {
  background: rgba(115, 103, 240, 0.12) !important;
  border-radius: 6px !important;
  border: 1px solid #7367F0 !important;
  &:hover {
    box-shadow: 0px 1px 10px 1px #7367F0 !important;
  }
  .integrator-service-create-button-privacy-title {
    color: #7367F0 !important;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
  .integrator-service-create-button-privacy-description {
    color: #7367F0 !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}

.integrator-service-create-button-privacy {
  background: rgba(108, 117, 125, 0.12) !important;
  border: 1px solid #82868B;
  border-radius: 6px !important;
  &:hover {
    box-shadow: 0px 1px 10px 1px #82868B !important;
  }
  .integrator-service-create-button-privacy-title {
    color: #82868B !important;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
  .integrator-service-create-button-privacy-description {
    color: #82868B !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
}
</style>